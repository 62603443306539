/**
 * Loyalty scheme - Cart - Spend points
 * Web Components configuration for the loyalty scheme spending points in the cart
 * cart/payment
 *
 * Components:
 * + Spend points
 */

// Page URL
const pageURL = window.location.href

// Cart payment page
if (pageURL.includes('cart/payment')) {
  // If - Cart component
  if (document.querySelector('.tn-cart-component')) {
    // If not empty cart message
    if (!document.querySelector('.tn-empty-cart-message')) {
      // Session Detail
      const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
      const encryptedSessionKey = btoa(session.key)

      // Create Element
      const cartSpendPoints = document.createElement('twc-loyalty-scheme-points-spend')

      // Add dataset
      cartSpendPoints.dataset.tnew = encryptedSessionKey

      // Add component before gift vouchers
      const cartGiftVoucherBlock = document.querySelector('.tn-gift-certificates-redemption-component')
      cartGiftVoucherBlock.before(cartSpendPoints)
    }
  }
}
