import { pageNames } from './config'

/**
 * OV Connect Page
 * Create a pseduo page in the account area for OV Connect form. Add the
 * OV Connect component to show the form.
 *
 * Components:
 * + OV Connect Form
 */

// Page URL
const pageURL = window.location.href

// Query String / URL Params
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

// Account pages
if (pageURL.includes('account')) {
  /**
   * Account navigation
   * Add additional link for OV Connect
   */

  // Nav
  const nav = document.querySelector('.tn-area-nav__list')
  if (nav) {
    // Add navigation item
    const listItem = document.createElement('li')
    listItem.setAttribute('class', 'tn-area-nav__list-item')
    listItem.innerHTML = `<a href="/account/update?type=ovconnect">${pageNames.ovConnect}</a>`

    // Account update page
    if (pageURL.includes('account/update')) {
      /**
       * Local membership pseudo page & account card
       * Add local membership pseudo page, hide all
       * standard content of page and add account card.
       */
      if (urlParams.get('type') === 'ovconnect') {
        // Find upcoming events nav item
        const upcomingEventsNavItem = [...nav.children].find(child => child.innerHTML.includes('Upcoming Events'))

        // Add OV connect link after upcoming events item
        upcomingEventsNavItem.after(listItem)

        // Find account info nav item
        const AccountInfoNavItem = [...nav.children].find(child => child.innerHTML.includes(pageNames.account))

        // Rename memberships nav item
        if (AccountInfoNavItem) {
          AccountInfoNavItem.innerHTML = `<a href="/account/update">${pageNames.account}</a>`
        }

        // Components to edit
        const pageHeaderContainer = document.querySelector('.tn-page-layout__page-header-container')
        const pageH1 = document.querySelector('.tn-heading')
        const pageCopy = document.querySelector('.tn-heading-sub-text')

        // Reset page content
        const mainContainer = document.querySelector('.tn-page-layout__main-container')
        const accountUpdateForm = document.querySelector('#tn-update-form')
        accountUpdateForm.innerHTML = ''

        // New page layout
        document.title = `${pageNames.ovConnect} | The Old Vic`
        pageH1.innerHTML = pageNames.ovConnect
        pageCopy.innerHTML = ''
        mainContainer.append(pageHeaderContainer)

        // Fix navigation to highlight access membership item
        listItem.innerHTML = pageNames.ovConnect

        // Session detail
        const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
        const encryptedSessionKey = btoa(session.key)

        // Create element - OV connect form component
        const OVConnectFormComponent = document.createElement('twc-account-ov-connect-form')

        // Add dataset
        OVConnectFormComponent.dataset.tnew = encryptedSessionKey

        // Append OV connect form component
        mainContainer.append(OVConnectFormComponent)
      }
    }
  }
}
