/**
 * Extended Seat Types
 * Web Components configuration for the extended seat types component on the events page
 * /{productionSeasonId}/{performanceId}
 *
 * Components:
 * + Extended seat types
 */

const legendText = 'Key & Prices'

// If - Events page
if (document.querySelector('.tn-events-detail-page')) {
  // Session detail
  const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
  const encryptedSessionKey = btoa(session.key)

  // Product Detail
  const product = window.dataLayer.find(function (e) { return e.hasOwnProperty('product') }).product
  const performanceId = product.performanceId

  // Create Element
  const extendedSeatTypes = document.createElement('twc-events-extended-seat-types')

  // Add dataset
  extendedSeatTypes.dataset.tnew = encryptedSessionKey
  extendedSeatTypes.dataset.performanceId = performanceId

  let oldXHROpen = window.XMLHttpRequest.prototype.open

  window.XMLHttpRequest.prototype.open = function (method, url, async, user, password) {
    this.addEventListener('load', function () {
      if (this.responseText) {
        let response = JSON.parse(this.responseText)

        if ('ZoneColorList' in response && 'seats' in response) {
          setTimeout(() => {
            if (document.querySelector('.tn-syos')) {
              document.querySelector('.tn-syos').classList.add('tn-syos--legend-details-visible')
            }

            // Text adjustment - Key & Prices
            if (document.querySelector('.tn-syos-legend__desktop-heading')) {
              document.querySelector('.tn-syos-legend__desktop-heading').innerHTML = legendText
            }
            if (document.querySelector('.tn-syos-legend__btn-show-legend')) {
              document.querySelector('.tn-syos-legend__btn-show-legend').innerHTML = legendText
            }
            if (document.querySelector('.tn-syos-legend__btn-show-legend-mobile')) {
              document.querySelector('.tn-syos-legend__btn-show-legend-mobile').innerHTML = legendText
            }
            extendedSeatTypes.dataset.seats = JSON.stringify(response.seats)
            const event = document.querySelector('.tn-events-detail-page')
            event.append(extendedSeatTypes)
          }, 2)
        }
      }
    })

    // Pass the call through to the original function
    return oldXHROpen.apply(this, arguments)
  }
}
