/**
 * Performance information
 * Web Components configuration for the performance information component on the events page
 * /{productionSeasonId}/{performanceId}
 *
 * We either use the product type keyword to determine what modal to show, or use the purchase instructions
 * / other unclassed text to find PwC previews.
 *
 * Components:
 * + Performance information
 */

// If - Events page
if (document.querySelector('.tn-events-detail-page')) {
  // Session Detail
  const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
  const encryptedSessionKey = btoa(session.key)

  const purchaseInstructionsParagraphs = document.querySelector('.tn-event-detail__purchase-instructions')

  // Find performance text
  const nonClassedParagraphs = [...document.querySelector('.tn-event-detail__performance-details-container').querySelectorAll('p:not([class])')]
  const informationParagraphs = []
  for (const paragraphs of nonClassedParagraphs) {
    if (!['<strong>Please note, seats have not been reserved until they are added to your basket.</strong>', ''].includes(paragraphs.innerHTML)) {
      informationParagraphs.push(`<p>${paragraphs.innerHTML}</p>`)
    }
  }

  // Back up performance text
  if (informationParagraphs.length === 0) {
    informationParagraphs.push(`<p>${purchaseInstructionsParagraphs.innerHTML}</p>`)
  }

  // If product type or PwC
  if (document.querySelector('.tn-product-type-name') || informationParagraphs[0].includes('PwC')) {
    // Create Element
    const performanceInformation = document.createElement('twc-events-performance-information')

    // Add dataset
    performanceInformation.dataset.performanceType = (document.querySelector('.tn-product-type-name')) ? document.querySelector('.tn-product-type-name').innerHTML.trim() : 'PwC'
    performanceInformation.dataset.performanceText = informationParagraphs.join('')
    performanceInformation.dataset.tnew = encryptedSessionKey

    // Append performance information
    const eventsDetailPage = document.querySelector('.tn-events-detail-page')
    eventsDetailPage.after(performanceInformation)
  }
}
