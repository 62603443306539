import { pageNames } from './config'

/**
 * Access Membership Page
 * Create a pseduo page in the account area for Access membership. Add the
 * Access Membership component to show existing points, or sign up to the
 * scheme.
 *
 * Components:
 * + Access membership
 */

// Page URL
const pageURL = window.location.href

// Query String / URL Params
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

// Account pages
if (pageURL.includes('account')) {
  /**
   * Account navigation
   * Add additional link for access membership
   */

  // Nav
  const nav = document.querySelector('.tn-area-nav__list')
  if (nav) {
    // Add navigation item
    const listItem = document.createElement('li')
    listItem.setAttribute('class', 'tn-area-nav__list-item')
    listItem.innerHTML = `<a href="/account/memberships?type=access">${pageNames.access}</a>`

    // Find upcoming events nav item
    const upcomingEventsNavItem = [...nav.children].find(child => child.innerHTML.includes('Upcoming Events'))

    // Add access membership before upcoming events item
    upcomingEventsNavItem.before(listItem)

    // Memberships page
    if (pageURL.includes('account/memberships')) {
      /**
       * Access membership pseudo page & account card
       * Add access membership pseudo page, hide all
       * standard content of page and add account card.
       */
      if (urlParams.get('type') === 'access') {
        // Find memberships nav item
        const membershipsNavItem = [...nav.children].find(child => child.innerHTML.includes(pageNames.memberships))

        // Rename memberships nav item
        membershipsNavItem.innerHTML = `<a href="/account/memberships">${pageNames.memberships}</a>`

        // Components to edit
        const pageHeaderContainer = document.querySelector('.tn-page-layout__page-header-container')
        const pageH1 = document.querySelector('.tn-heading')
        const pageCopy = document.querySelector('.tn-heading-sub-text')

        // Reset page content
        const mainContainer = document.querySelector('.tn-page-layout__main-container')
        mainContainer.innerHTML = ''

        // New page layout
        document.title = `${pageNames.access} | The Old Vic`
        pageH1.innerHTML = pageNames.access
        pageCopy.innerHTML = ''
        mainContainer.append(pageHeaderContainer)

        // Fix navigation to highlight access membership item
        listItem.innerHTML = pageNames.access

        // Session detail
        const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
        const encryptedSessionKey = btoa(session.key)

        // Create element - Access membership component
        const accessMembershipComponent = document.createElement('twc-account-access-membership')

        // Add dataset
        accessMembershipComponent.dataset.tnew = encryptedSessionKey

        // Append access membership component
        mainContainer.append(accessMembershipComponent)
      }
    }
  }
}
