/**
 * Member login bar
 * Web Components configuration for the member login bar component on the events page
 * /{productionSeasonId}/{performanceId}
 *
 * Components:
 * + Member login bar
 */

// Page URL
const pageURL = window.location.href

// If - Calendar / Events page
if (pageURL.includes('/events') || document.getElementsByClassName('tn-events-detail-page')[0]) {
  // Session Detail
  const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session

  // Create Element
  const memberLoginBar = document.createElement('twc-events-member-login-bar')

  // Add dataset
  memberLoginBar.dataset.isLoggedIn = (!session.customer.isAnonymous && !session.customer.isGuest)

  // Append Member login bar
  const subNavComponentDiv = document.querySelector('.tn-subnav-component')
  subNavComponentDiv.after(memberLoginBar)
}
