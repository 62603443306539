/**
 * Membership Upsell Card
 * Display a membership upsell card if no memberships
 *
 * Components:
 * + Membership upsell card
 */

// Page URL
const pageURL = window.location.href

// Account pages
if (pageURL.includes('account')) {
  // Nav
  const nav = document.querySelector('.tn-area-nav__list')
  if (nav) {
    // Memberships page
    if (pageURL.includes('account/update')) {
      // Reset page content
      const mainContainer = document.querySelector('.tn-page-layout__main-container')

      // Session detail
      const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
      const encryptedSessionKey = btoa(session.key)

      // Create element - Membrship upsell component
      const membershipUpsellComponent = document.createElement('twc-account-membership-upsell')

      // Add dataset
      membershipUpsellComponent.dataset.tnew = encryptedSessionKey

      // Prepend membership upsell component
      mainContainer.prepend(membershipUpsellComponent)
    }
  }
}
