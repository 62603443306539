/**
 * Auto donation
 * Web Components configuration for the auto donation component on the cart details page
 * cart/details
 *
 * Components:
 * + Auto donation
 */

// Memberships
const membershipNames = [
  'Advance',
  'Evolve',
  'Discover',
  'Thrive',
  'Innovate',
  'Vision'
]

// Page URL
const pageURL = window.location.href

// Is Test
const isTest = (pageURL.includes('test.tnhs.cloud') || pageURL.includes('dev0.tnhs.cloud'))

// Cart details page
if (pageURL.includes('cart/details')) {
  // If - Cart component
  if (document.querySelector('.tn-cart-component')) {
    // If not empty cart message
    if (!document.querySelector('.tn-empty-cart-message')) {
      // Session Detail
      const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
      const encryptedSessionKey = btoa(session.key)

      // Transaction Products
      const transaction = window.dataLayer.find(function (e) { return e.hasOwnProperty('transactionId') })
      const transactionProducts = (transaction) ? transaction.transactionProducts : []
      const transactionContributions = (transactionProducts.length > 0) ? transactionProducts.filter(product => product.category === 'Contribution') : []
      const transactionDonations = (transactionContributions.length > 0) ? transactionContributions.filter(product => !membershipNames.includes(product.name)) : []

      // Create Element
      const cartUpsellDonation = document.createElement('twc-cart-upsell-donation')

      // Add dataset
      cartUpsellDonation.dataset.isTest = isTest
      cartUpsellDonation.dataset.isAutoDonation = (transactionDonations.length === 0)
      cartUpsellDonation.dataset.tnew = encryptedSessionKey

      // Prepend Upsell Donation
      const cartComponentDiv = document.querySelector('.tn-cart-component')
      cartComponentDiv.prepend(cartUpsellDonation)

    // Empty cart, remove upsell donation cookie
    } else {
      document.cookie = 'twc-upsell-donation=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }
  }
}

// Cart receipt page
if (pageURL.includes('cart/receipt')) {
  // Remove upsell donation cookie automatically when purchase is complete
  document.cookie = 'twc-upsell-donation=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}
