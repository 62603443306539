/**
 * Page names
 * Centerally manage names of pages
 * and links so they work across
 * different functionality
 */
export const pageNames = {
  access: 'Access Membership',
  memberships: 'OV Together Membership',
  loyaltyScheme: 'OV Loyalty',
  local: 'OV Local',
  ovConnect: 'OV Connect',
  account: 'Account info'
}
