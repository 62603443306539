// TNEW Web Components
import './tnew-web-components/index'
import './syos-rearrange.js'

(() => {
  console.log('tnew web components 5.0.3')

  // A price type placeholder box with white space characters
  // Hide it when there's no actual text inside
  const emptyElements = document.querySelectorAll('.tn-empty-cart-message, #instructions-choose-zone, .tn-receipt-page .tn-heading, .tn-plug-in-add-ons-item-image')
  if (emptyElements && emptyElements.length) {
    emptyElements.forEach(element => {
      if (!element.innerText.length) {
        element.style.display = 'none'
      }
    })
  }
})()
