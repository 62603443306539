/**
 * Loyalty scheme - Cart - Points instance
 * Web Components configuration for the loyalty scheme points instance in the cart
 * cart/details
 * cart/payment
 *
 * Components:
 * + Points instance
 */

// Page URL
const pageURL = window.location.href

// Cart details / payment page
if (pageURL.includes('cart/details') || pageURL.includes('cart/payment')) {
  // If - Cart component
  if (document.querySelector('.tn-cart-component')) {
    // If not empty cart message
    if (!document.querySelector('.tn-empty-cart-message')) {
      // Session Detail
      const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
      const encryptedSessionKey = btoa(session.key)

      // Loop instances
      const cartInstances = document.getElementsByClassName('tn-cart__section-items')
      for (const instance of [...cartInstances]) {
        // Find item element
        const instanceItem = instance.querySelector('.tn-cart-item')

        // Add data price
        const instanceId = { ...instanceItem.dataset }.tnPerformance
        if (instanceId) {
          /**
           * Cart Details (Products)
           * The products data layer is only visible on the cart/details page.
           * We get the data on this page and add the resulting total to local storage.
           *
           * We can then recall this on the cart/payment page.
           */
          if (pageURL.includes('cart/details') || pageURL.includes('cart/payment')) {
            const lineItems = [...document.getElementsByClassName('tn-cart-item-summary')]
            for (const lineItem of lineItems) {
              const cartItem = lineItem.closest('.tn-cart-item')
              const performanceItem = cartItem.getAttribute('data-tn-performance')
              if (performanceItem) {
                const lineItemPriceElement = lineItem.querySelector('.tn-cart-item-summary__property--price-total')
                const lineItemPrice = parseFloat(lineItemPriceElement.innerText.replace('Price:', '').replace('£', '')).toFixed(2)

                // Create Element
                const cartPointsInstance = document.createElement('twc-loyalty-scheme-points-instance')

                // Add dataset
                cartPointsInstance.dataset.tnew = encryptedSessionKey
                cartPointsInstance.dataset.price = 0
                cartPointsInstance.dataset.price = lineItemPrice

                // Add loyalty points list item
                const loyaltyPointsListItem = document.createElement('li')
                loyaltyPointsListItem.setAttribute('class', 'tn-cart-item-summary__property tn-cart-item-summary__property--primary tn-cart-item-summary__property--loyalty-points')

                // Append component to list item
                loyaltyPointsListItem.append(cartPointsInstance)

                // Append list item to summary
                lineItem.append(loyaltyPointsListItem)
              }
            }
          }
        }
      }
    }
  }
}
