/**
 * Loyalty scheme - Cart - Points cart
 * Web Components configuration for the loyalty scheme points cart in the cart
 * cart/details
 * cart/payment
 *
 * Components:
 * + Points cart
 */

// Page URL
const pageURL = window.location.href

// Cart details / payment page
if (pageURL.includes('cart/details') || pageURL.includes('cart/payment')) {
  // If - Cart component
  if (document.querySelector('.tn-cart-component')) {
    // If not empty cart message
    if (!document.querySelector('.tn-empty-cart-message')) {
      // Session Detail
      const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
      const encryptedSessionKey = btoa(session.key)

      // Create Element
      const cartPointsCart = document.createElement('twc-loyalty-scheme-points-cart')

      // Add dataset
      cartPointsCart.dataset.tnew = encryptedSessionKey
      cartPointsCart.dataset.total = 0

      /**
       * Cart Details (Products)
       * The products data layer is only visible on the cart/details page.
       * We get the data on this page and add the resulting total to local storage.
       *
       * We can then recall this on the cart/payment page.
       */
      if (pageURL.includes('cart/details') && window.dataLayer.find(function (e) { return e.hasOwnProperty('transactionId') })) {
        // Products
        const products = window.dataLayer.find(function (e) { return e.hasOwnProperty('transactionId') }).transactionProducts

        // Valid products
        const invalidProductCategories = ['Contribution', 'Gift Certificate']
        const validProducts = (products) ? products.filter(product => !invalidProductCategories.includes(product.category)) : null
        cartPointsCart.dataset.total = (validProducts) ? validProducts.reduce((a, b) => a + b.price * b.quantity, 0) : 0

        // Add to local storage - Total
        localStorage.setItem('total', cartPointsCart.dataset.total)
      }

      // Cart payment page (Local storage)
      if (pageURL.includes('cart/payment')) {
        cartPointsCart.dataset.total = localStorage.getItem('total')
      }

      // Append component
      const cartSubTotal = document.querySelector('.tn-cart-totals__line-item--subtotal')
      const loyaltyPointsLineItem = document.createElement('div')
      loyaltyPointsLineItem.setAttribute('class', 'tn-cart-totals__line-item tn-cart-totals__line-item--loyalty')

      // Append component
      loyaltyPointsLineItem.append(cartPointsCart)

      // Add line item after subtotal
      cartSubTotal.after(loyaltyPointsLineItem)
    }
  }
}
