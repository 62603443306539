/**
 * Loyalty scheme - Cart - Points receipt
 * Web Components configuration for the loyalty scheme points receipt in the cart
 * cart/receipt
 *
 * Components:
 * + Points receipt
 */

// Page URL
const pageURL = window.location.href

// Cart receipt page
if (pageURL.includes('cart/receipt')) {
  // Session Detail
  const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
  const encryptedSessionKey = btoa(session.key)

  const cartSubTotal = document.querySelector('.tn-cart-totals__line-item--subtotal')

  // Create Element
  const cartPointsReceipt = document.createElement('twc-loyalty-scheme-points-receipt')

  // Add dataset
  cartPointsReceipt.dataset.tnew = encryptedSessionKey
  cartPointsReceipt.dataset.orderId = location.pathname.split('/')[3]

  // Append component
  const loyaltyPointsEarnedLineItem = document.createElement('div')
  loyaltyPointsEarnedLineItem.setAttribute('class', 'tn-cart-totals__line-item tn-cart-totals__line-item--loyalty loyalty-earned')

  // Append component
  loyaltyPointsEarnedLineItem.append(cartPointsReceipt)

  // Add line item after subtotal
  cartSubTotal.after(loyaltyPointsEarnedLineItem)

  // Create element - Loyalty account card
  const loyaltyAccountCard = document.createElement('twc-loyalty-scheme-account-card')

  // Add dataset
  loyaltyAccountCard.dataset.tnew = encryptedSessionKey
  loyaltyAccountCard.dataset.page = 'cart/receipt'
  loyaltyAccountCard.dataset.orderId = location.pathname.split('/')[3]

  // Append loyalty account card
  const cartTotals = document.querySelector('.tn-cart-totals')
  cartTotals.before(loyaltyAccountCard)

  // Clear local storage
  localStorage.clear()
}
