/**
 * OV Together Membership
 * Add unique styling and configuration for each membership on the
 * membership page.
 *
 * OV Local membership is hidden on this page already so it can
 * sit on its own page.
 *
 * Components:
 * + OV Together membership
 */

// Page URL
const pageURL = window.location.href

// Query String / URL Params
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

// Memberships page
if (pageURL.includes('account/memberships')) {
  // Session detail
  const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
  const encryptedSessionKey = btoa(session.key)

  // Get main container
  const mainContainer = document.querySelector('.tn-page-layout__main-container')

  // Create element - Membrship upsell component
  const membershipUpsellComponent = document.createElement('twc-account-membership-upsell')

  // Loop membership list
  const membershipsList = document.querySelector('.tn-membership-summary__list')
  if (membershipsList) {
    for (const membership of [...membershipsList.children]) {
      // Find properties for data set
      const membershipSummary = membership.querySelector('.tn-membership-summary')
      const membershipDescription = membership.querySelector('.tn-membership-summary__description')
      const membershipId = membershipSummary.getAttribute('data-tn-membership-id')
      const membershipName = membershipDescription.innerHTML.replace('OV', '').trim()

      let membershipExpiry = ''
      if (membership.querySelector('.tn-membership-summary__expiration-date-value')) {
        membershipExpiry = membership.querySelector('.tn-membership-summary__expiration-date-value').innerHTML.trim()
      }

      // Create element - OV Together membership
      const OVTogetherMembership = document.createElement('twc-account-ov-together-membership')

      // Add dataset (Together membership)
      OVTogetherMembership.dataset.tnew = encryptedSessionKey
      OVTogetherMembership.dataset.membershipId = membershipId
      OVTogetherMembership.dataset.membershipName = membershipName
      OVTogetherMembership.dataset.membershipExpiry = membershipExpiry

      // Add dataset (Upsell component)
      membershipUpsellComponent.dataset.membershipName = membershipName

      // Append membership to main container
      mainContainer.append(OVTogetherMembership)
    }

    // Remove membership list
    membershipsList.remove()
  }

  // Standard membership page only
  if (!urlParams.get('type')) {
    // Add dataset (Upsell component)
    membershipUpsellComponent.dataset.tnew = encryptedSessionKey

    // Append membership upsell component
    mainContainer.append(membershipUpsellComponent)
  }
}
